import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`;

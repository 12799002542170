import React from 'react';
import { Link, navigate } from 'gatsby';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import './../../styles/globalStyles.less';
import { FormInput } from '../FormInput/FormInput';
import { useLogin, UseLoginProps } from '../../utils/hooks/useLogin';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import { ROUTES } from '../../constants/routes';

export const SignInForm = () => {
  const { isTablet } = useMediaQuery();
  const { Paragraph } = Typography;

  const { handleLogin, loading, error } = useLogin();

  const onFinish = async (inputs: UseLoginProps) => {
    const { data } = await handleLogin(inputs);

    if (data) {
      navigate(ROUTES.HOME);
    }
  };
  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Email jest wymagany!' }]}
          validateStatus={error ? 'error' : loading ? 'validating' : 'success'}
          hasFeedback={loading || error ? true : false}
        >
          <FormInput placeholder={'Email'} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Hasło jest wymagane!',
            },
          ]}
          validateStatus={error ? 'error' : loading ? 'validating' : 'success'}
          hasFeedback={loading || error ? true : false}
        >
          <Input.Password className={'formInput'} placeholder={'Hasło'} />
        </Form.Item>

        {error && (
          <Paragraph type="danger" className="errot-text">
            Błędne dane logowania
          </Paragraph>
        )}

        <Row align="middle" justify={isTablet ? 'space-between' : 'end'}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-mobile sign-in-page-main-button"
              >
                Zaloguj się
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={isTablet ? 'end' : 'center'}>
          <Link to={ROUTES.FORGOT_PASSWORD}>Nie pamiętasz hasła?</Link>
        </Row>
      </Form>
    </>
  );
};

import React from 'react';
import { Link, navigate } from 'gatsby';
import { Button, Col, Row, Typography, Layout, Divider } from 'antd';
import { SignInForm } from '../components/SignInForm/SignInForm';
import useMediaQuery from '../utils/hooks/useMediaQuery';
import { globalStyles } from '../styles/variables';
import './sign-in.less';
import { Logotype } from '../components/Navbar/components/Logotype';
import { ROUTES } from './../constants/routes';
import { API_OAUTH_FACEBOOK, API_OAUTH_GOOGLE } from '../constants/api';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const SignIn = () => {
  const { Title, Paragraph } = Typography;
  const { matches } = useMediaQuery(globalStyles.minScreenWidth.lg);

  return (
    <Layout className="sign-in-page">
      <Row className="sign-in-logotype">
        <Logotype />
      </Row>
      <Row align="middle" justify="center">
        <Col span={matches ? 14 : 24}>
          <Row justify="center">
            <Col flex={1 / 6}>
              <Title level={3} className="sign-in-page-heading">
                Zaloguj się
              </Title>
              <SignInForm />
              <Row align="middle" justify="center">
                <Divider />
              </Row>
              <Link to={API_OAUTH_FACEBOOK.API_CONNECT}>
                <Button type="primary" className="btn-mobile social-auth-btn">
                  <FacebookOutlined />
                  <Text>Kontynuuj przez Facebook</Text>
                </Button>
              </Link>
              <Link to={API_OAUTH_GOOGLE.API_CONNECT}>
                <Button type="primary" className="btn-mobile social-auth-btn">
                  <GoogleOutlined />
                  <Text>Kontynuuj przez Google</Text>
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col span={matches ? 10 : 24} className="sign-in-page-aside">
          <Row
            align="middle"
            justify="center"
            className="container-full-height"
          >
            <Col flex={matches ? 4 / 7 : 1 / 6}>
              <Title level={4}>Nie masz jeszcze konta?</Title>
              <Paragraph>Zarejestruj się i zacznij naukę już dziś.</Paragraph>
              <Button
                type="ghost"
                onClick={() => navigate(ROUTES.SIGN_UP)}
                className="outline-white btn-mobile sign-in-page-aside-button"
              >
                Załóż konto
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default SignIn;

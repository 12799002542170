export const API_URL = {
  BASE_URL: 'https://wosbe.vicoop.com',
};

export const API_OAUTH_GOOGLE = {
  API_CONNECT: `${API_URL.BASE_URL}/api/connect/google`,
  API_AUTH: `${API_URL.BASE_URL}/api/auth/google/callback`,
};

export const API_OAUTH_FACEBOOK = {
  API_CONNECT: `${API_URL.BASE_URL}/api/connect/facebook`,
  API_AUTH: `${API_URL.BASE_URL}/api/auth/facebook/callback`,
};

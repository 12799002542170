import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { LOGIN_MUTATION } from '../../queries/loginMutation';

export type UseLoginProps = {
  email: String;
  password: String;
};

export const useLogin = () => {
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION);

  const { dispatch } = useContext(GlobalDispatchContext);

  const handleLogin = useCallback(
    async ({ email, password }: UseLoginProps) =>
      login({
        variables: {
          input: {
            identifier: `${email}`,
            password: `${password}`,
            provider: 'local',
          },
        },
        onCompleted: ({ login }) => {
          if (typeof window !== 'undefined') {
            localStorage?.setItem('AUTH_TOKEN', login.jwt);
            localStorage?.setItem('userid', login.user.id);
          }

          dispatch({ type: 'SIGN_IN' });

          return login;
        },
        onError: error => {
          console.log('Error', error);
        },
      }),
    []
  );

  return { handleLogin, loading, error };
};
